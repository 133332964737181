import React, { useState } from 'react';
import logoImg from '../image/02.jpg'
import WalletConnect from './WalletConnect'

const Header = ({defaultAccountChange}) => {
    const handleAccountChange = async (value) => {
        defaultAccountChange(value);
    }
    
    return (
        <div>
            <img src={logoImg} alt="Banner" className='logoImage' />
            <WalletConnect defaultAccountChange={handleAccountChange} />
        </div>
    )
}

export default Header
