import React, { useState } from 'react'

const Number = ({passBack}) => {
    const [number1, setNumber1] = useState(0);
    const [number2, setNumber2] = useState(0);
    const [number3, setNumber3] = useState(0);

    const numberWrapperStyle = {
        marginTop: '5vh',
        display: 'flex',
        flexDirection: 'row',
        marginButtom: '5vh',
        height: '20vh',
        backgroundColor: '#55375B',
        borderRadius: '20px',
    }
    const numberStyle = {
        marginLeft: '40px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1'
    }

    const USDTStyle = {
        display: 'flex',
        flex: '4',
        justifyContent: 'center',
        marginTop: '9vh'
    }

    const submitValue = () => {
        let sum = number1 * 100 + number2 * 10 + number3
        console.log(sum);
        if (sum <= 888 && sum >=10) {
            passBack(sum)
        }
        else
            console.log("Invalid Number")
    }

    return (
        <div>
            <div className="NumberWrapper" style={numberWrapperStyle}>
                <div className='Number1' style={numberStyle}>
                    <div className='PlusButton' onClick={() => {
                        if (number1 <= 8)
                            setNumber1(number1 + 1)
                        else
                            setNumber1(0)
                    }}>
                        +
                    </div>
                    <div className='NumberInNumberJS'>{number1}</div>
                    <div className='MinusButton' onClick={() => {
                        if (number1 >= 1)
                            setNumber1(number1 - 1)
                        else
                            setNumber1(9)
                    }}>
                        -
                    </div>
                </div>
                <div className='Number2' style={numberStyle}>
                    <div className='PlusButton' onClick={() => {
                        if (number2 <= 8)
                            setNumber2(number2 + 1)
                        else
                            setNumber2(0)
                    }}>
                        +
                    </div>
                    <div className='NumberInNumberJS'>{number2}</div>
                    <div className='MinusButton' onClick={() => {
                        if (number2 >= 1)
                            setNumber2(number2 - 1)
                        else
                            setNumber2(9)
                    }}>
                        -
                    </div>
                </div>
                <div className='Number3' style={numberStyle}>
                    <div className='PlusButton' onClick={() => {
                        if (number3 <= 8)
                            setNumber3(number3 + 1)
                        else
                            setNumber3(0)
                    }}>
                        +
                    </div>
                    <div className='NumberInNumberJS'>{number3}</div>
                    <div className='MinusButton' onClick={() => {
                        if (number3 >= 1)
                            setNumber3(number3 - 1)
                        else
                            setNumber3(9)
                    }}>
                        -
                    </div>
                </div>
                <div className='textUSDT' style={USDTStyle}>
                    USDT
                </div>
            </div>
            <div>
                <button className='IDOJoinButton' onClick={submitValue}> Join IDO </button>
            </div>
        </div>
    )
}

export default Number
