import React from 'react'
import Number from './Number'
const Body = () => {

    const handlepassBack = (value) => {
        console.log("Handling pass back value")
        console.log(value);
    }
    return (
        <div className='bodySections'>
            <div className='bodySec1'>
                <div className='bodySec1_Banner'>
                    BannerImage Right Here
                </div>
                <div className='bodySec1_textSection'>
                    Countdown to the end of IDO
                </div>
            </div>
            <div className='bodySec2'>
                <div className='bodySec2_textSection'>
                    Join IDO Now
                </div>
                <div className='bodySec2_textSection'>
                    Choose the amount you want to join
                </div>
                <Number passBack={handlepassBack} />
            </div>
            <div className='bodySec3'>
                Section3
            </div>
            <div className='bodySec4'>
                Section4
            </div>
            <div className='bodySec5'>
                Section5
            </div>
        </div>
    )
}

export default Body
